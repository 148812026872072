import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, switchMap } from 'rxjs';
import { StorageNames } from '../../classes/storage-names';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  isCheckRefresh: boolean = false;
  constructor(
    private _auth: AuthService,
    private _router: Router,
    // private _toastrHelper: ToastrHelperService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    let token = localStorage.getItem(StorageNames.TOKEN);

    const blackListUrls = ['ipapi.co'];

    for (let i = 0; i < blackListUrls.length; i++) {
      if (request.url.includes(blackListUrls[i])) {
        return next.handle(request);
      }
    }

    if (!token) {
      return next.handle(request);
    }

    let clonedRequest = request.clone({
      headers: request.headers
        .set('Authorization', 'Bearer ' + token)
        .set(
          'Delivery-ApiKey',
          localStorage.getItem(StorageNames.DELIVERY_API_KEY)!,
        ),
    });

    return next.handle(clonedRequest).pipe(
      catchError((err) => {
        if (this.isCheckRefresh) return next.handle(clonedRequest);
        if (err instanceof HttpErrorResponse && err.status == 401) {
          const userToken = localStorage.getItem(StorageNames.TOKEN);
          const refreshToken = localStorage.getItem(StorageNames.REFRESH_TOKEN);
          if (!userToken || !refreshToken) {
            this.logout();
            throw err;
          }

          this.isCheckRefresh = true;

          return this._auth.checkRefreshToken(refreshToken, userToken).pipe(
            switchMap((userResponse) => {
              if (!userResponse) throw 'Error';
              this._auth.saveTokens(userResponse);
              clonedRequest.headers.set(
                'Authorization',
                'Bearer ' + userResponse.JWTToken,
              );
              this.isCheckRefresh = false;
              return next.handle(clonedRequest);
            }),
            catchError((error) => {
              this.isCheckRefresh = false;
              throw error;
            }),
          );
        }

        throw err;
      }),
    );

    // return next.handle(clonedRequest).pipe(catchError(err => {
    //   if (err instanceof HttpErrorResponse && err.status == 401) {
    //     this._auth.removeUserData();
    //     // this._toastrHelper.errorMessage('Login session expired');
    //     this._router.navigate(['/auth']);
    //   }

    //   throw err;
    // }));
  }

  private logout() {
    this._auth.removeUserData();
    this._router.navigate(['/auth']);
  }
}
